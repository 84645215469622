<template>
<div>
  <v-card max-width="370" class="mx-auto mt-5" flat>

    <Errors v-bind:errors="errors"/>

    <v-card-title> 
      <i>
      {{student.firstname}} 
      {{student.lastname}}
      </i>
      <v-spacer/>
      <BalanceLessonAccount v-bind:balance="student.balanceLessonAccount"/>
    </v-card-title>
    <v-card-subtitle>
        Schüler:in
    </v-card-subtitle>
    <v-card-text>
      <p>
        Klasse: {{ student.year }}
      </p>
      <p>
        Schulart: {{ student.schooltype }}
      </p>
      <p v-if="this.student.school">
        Schule: {{ student.school }}
      </p>
      <p v-if="this.student.phone">
        Telefon: <a v-bind:href="'phone:' + student.phone">{{ student.phone }}</a>
      </p>
      <p v-if="this.student.parentPhone">
        Telefon Eltern: <a v-bind:href="'phone:' + student.parentPhone">{{ student.parentPhone }}</a>
      </p>
      <p v-if="this.student.email">
        E-Mail: 
        <a v-bind:href="'mailto:' + student.email">{{ student.email }}</a>
      </p>
      <p v-if="this.student.address" style="white-space: pre-wrap;">
        Adresse: {{ student.address }}
      </p>

      <v-divider v-if="isAdmin && (student.contractStatus || student.monthlyCosts || student.lessonCost)" class="my-5"/>

      <div>
        <p v-if="student.contractStatus">
          Vertragsstatus: {{ student.contractStatus }} 
          <span v-if="student.contractStatus == 'gekündigt' && student.terminationDate"> 
            auf {{student.terminationDate | formatDate }}
          </span>
        </p>
        <p v-if="student.monthlyContractCosts">
          Monatliche Vertragskosten: {{student.monthlyContractCosts}}€
        </p>
        <p v-if="student.lessonCosts">
          Kosten pro (vertragsfreier) Einzelstunde: {{ student.lessonCosts}}€
        </p>
      </div>

    </v-card-text>

    <!-- Series of this Student -->
    <v-card-title>
      Serien
    </v-card-title>
    <v-card-text>
      <div 
        v-for="series in allSeries" 
        v-bind:key="series.id"
        >
          <SeriesInfo v-bind:series="series"/>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-container>

        <v-row class="my-2">
            <v-btn
              color="primary"
              width="100%"
              depressed
              @click="$router.push('/lessonaccount/' + studentId)">
              Stundenkonto
            </v-btn>
          </v-row>

          <v-row class="my-2">
            <v-btn
              color="primary"
              width="100%"
              depressed
              @click="$router.push('/editstudent/' + studentId)">
              Infos bearbeiten
            </v-btn>
          </v-row>

          <v-row class="my-2 mt-8">
            <v-btn
              color="secondary"
              width="100%"
              depressed
              @click="$router.push('/eventsof/student/' + student.id)">
              Stunden anzeigen
            </v-btn>
          </v-row>
        </v-container>
    </v-card-actions>

    <!-- Last Events of this student -->
    <v-card-text v-if="events.length">
      <div 
        v-for="event in events" 
        v-bind:key="event.id"
        >
          <EventInfos v-bind:event="event"/>
      </div>
    </v-card-text>


  </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex';
import { studentsCollection, eventsCollection, auth } from '@/firebase';
import {formatDate} from '@/assets/js/timeUtils.js';
import Errors from '@/components/Errors.vue';
import BalanceLessonAccount from '@/components/BalanceLessonAccount.vue';
import EventInfos from '@/components/EventInfos.vue';
import SeriesInfo from '@/components/SeriesInfo.vue';
import { seriesCollection } from '../firebase';

export default {
  name: 'InfoStudent',
  props: ['studentId'],

  components: {
    Errors,
    BalanceLessonAccount,
    EventInfos,
    SeriesInfo,
  },

  data() {
    return {
      errors: [],
      student: {},
      events: [],
      allSeries: [],
    }
  },

  computed: {
    ...mapState(['isAdmin']),
  },

  async created() {
    this.getStudent();
    this.getAllSeries();
  },

  methods: {

    async getStudent(){

      try {
        const studentDoc = await studentsCollection.doc(this.studentId).get();
        this.student = studentDoc.data();
        this.student.id = studentDoc.id;

      } catch { 
        this.errors.push({
          text: 'Die Schülerin / der Schüler konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        throw "Could not find this doument in firestore";
      }
    },

    async getAllSeries() {
      const instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

      seriesCollection
        .where("instituteId", "==", instituteId)
        .where("studentRef", "==", studentsCollection.doc(this.studentId))
        .orderBy('startOfSeries', "desc")
        .get()
        .then(snapshot => {
          this.allSeries = [];
          snapshot.forEach(doc => {
            let series = doc.data();
            series.id = doc.id;
            this.allSeries.push(series);
          })
        })
    },

    /**
     * @description Gets the last 20 events of this student to display them
     */
    async getLastEvents(){
      const instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
      const today = new Date().toISOString().substr(0,10);
      
      eventsCollection
        .where("instituteId", "==", instituteId)
        .where("studentRef", "==", studentsCollection.doc(this.studentId))
        .where('date', '<=', today)
        .orderBy('date', "desc")
        .orderBy('start', "desc")
        .limit(20)
        .get()
        .then(snapshot => {
          this.events = [];
          snapshot.forEach(doc => {
            let event = doc.data();
            event.id = doc.id;
            this.events.push(event);
          })
        })
    },

  }
}
</script>