<template>
  <div>

    <v-card
    :color="boxColor"
    outlined
    @click="redirectToSeriesInfo"
    class="mx-2 my-3"
    >

      <v-card-title class="text-body-1 font-weight-medium my-n2">
        {{teacher}}
      </v-card-title>
      <v-card-subtitle class="font-italic">
        {{student}}
      </v-card-subtitle>
      <v-card-text class="text-caption my-n3">
        {{series.weekday.name}}s, {{series.startOfSeries | formatDate }} - {{series.endOfSeries | formatDate}} <br>
        {{series.start}} - {{series.end}}, {{series.subject}}
      </v-card-text>
    
    </v-card>

  </div>
</template>

<script>

export default {
    name: 'SeriesInfo',
    props: ["series"],
    data() {
        return {
          student: "",
          teacher: "",
          boxColor: "accent"
        }
    },

    created() {
      this.getTeacherName();
      this.getStudentName();
      this.checkIfSeriesIsActive();
    },

    methods: {

      async getTeacherName() {
        const teacherdoc = await this.series.teacherRef.get();
        if (teacherdoc.exists) {
          this.teacher = teacherdoc.data().firstname + " " + teacherdoc.data().lastname;
        } else { console.log('No such document') }
      },

      async getStudentName() {
        const studentDoc = await this.series.studentRef.get();
        if (studentDoc.exists) {
          this.student = studentDoc.data().firstname + " " + studentDoc.data().lastname;
        } else { console.log('No such document') }
      },

      checkIfSeriesIsActive(){
        const today = new Date().toISOString().substr(0,10);

        if(this.series.endOfSeries < today){
          this.boxColor = "secondary"
        }
      },

      redirectToSeriesInfo() {
        /* redirect to EventInfo view and pass with it the id of the event */
        this.$router.push('/series/' + this.series.id);
      }
    }
}
</script>

<style scoped>

</style>